<template>
  <div class="text-center mb-4 mt-2">
    <h1 class="mb-3">
      {{
        $t(
          "BaseModelFields.ForgotPassword",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </h1>
    <div class="text-gray-400 fw-bold fs-5">
      {{
        $t(
          "PasswordChange.Description",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
  </div>
  <form
    class="form w-100 needs-validation"
    @submit="onSubmitPasswordChange"
    id="passwordChangeForLoggedUser"
    novalidate
  >
    <div class="mb-3 fv-row" data-sx-password-meter="true">
      <div>
        <label class="form-label fw-bolder fs-6 required" for="password">
          {{
            $t(
              "PasswordChange.Password",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <div class="position-relative mb-3">
          <input
            class="form-control form-control-lg"
            type="password"
            placeholder=""
            name="password"
            id="password"
            required
            autocomplete="off"
            data-autofocus="true"
            v-model="userData.password"
            @keyup="validatePassword"
            @keyup.enter="onSubmitPasswordChange"
          />
          <div class="invalid-tooltip">
            {{
              this.$t(
                "FieldWarnings.TextNotValid",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </div>
        <div
          class="d-flex align-items-center mb-3"
          data-sx-password-meter-control="highlight"
        >
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
          ></div>
        </div>
      </div>
    </div>
    <div class="fv-row mb-5">
      <label
        class="form-label fw-bolder fs-6 required"
        for="password_confirmation"
        >{{
          $t(
            "PasswordChange.ConfirmPassword",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </label>
      <div class="position-relative">
        <input
          class="form-control form-control-lg"
          type="password"
          placeholder=""
          name="password_confirmation"
          id="password_confirmation"
          autocomplete="off"
          v-model="passwordConfirmation"
          @keyup="validatePassword"
          @keyup.enter="onSubmitPasswordChange"
          required
        />
        <div class="invalid-tooltip">
          {{
            this.$t(
              String.isNullOrWhiteSpace(passwordConfirmation)
                ? "FieldWarnings.TextNotValid"
                : "PasswordChange.PasswordDontMatch",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </div>
    </div>
    <div class="row">
      <template v-if="formPosting">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-md-6">
          <button
            @click="onSubmitPasswordChange"
            type="button"
            class="btn btn-lg btn-success w-100 mb-2"
          >
            <span class="indicator-label">
              {{
                $t(
                  "BaseModelFields.Save",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            @click="parentWindowModalClose"
            class="btn btn-lg btn-danger fw-bolder w-100"
          >
            {{
              $t(
                "BaseModelFields.Cancel",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>
    </div>
  </form>
</template>
<script>
import { PasswordMeterComponent } from "@/assets/ts/components";
import { onMounted, nextTick } from "vue";
import $ from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  name: "PasswordChange",
  data() {
    return {
      userData: {
        clientUrl: `${window.location.origin}/#/`,
        password: null,
        publicId: this.$route.params.key,
      },
      passwordConfirmation: null,
      formPosting: false,
      passwordDontMatch: false,
      windowParent: null,
      parentDocument: null,
    };
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }

    if (String.isNullOrWhiteSpace(this.userData.publicId)) {
      this.$router.push({
        path: "/",
      });
    }

    this.windowParent = window.parent;
    if (this.windowParent) {
      this.parentDocument = this.windowParent.document;
      if (this.parentDocument) {
        var iframe = $(this.parentDocument).find("iframe");
        if (iframe) {
          iframe.contents().find(".toolbar").remove();
        }
      }
    }
  },
  components: {},
  methods: {
    parentWindowModalClose() {
      if (this.windowParent && this.parentDocument) {
        var modalDismissButton = $(this.parentDocument).find(
          `#buttonModal [data-bs-dismiss="modal"]:first`
        );
        if (modalDismissButton) {
          modalDismissButton.trigger("click");
        }
      }
    },
    validatePassword() {
      this.passwordDontMatch =
        this.userData.password !== this.passwordConfirmation;
      if (this.passwordDontMatch) {
        $("#password_confirmation").addClass("is-invalid");
        $("#password_confirmation").removeClass("is-valid");
      } else {
        $("#password_confirmation").removeClass("is-invalid");
        $("#password_confirmation").addClass("is-valid");
      }
    },
    onSubmitPasswordChange(event) {
      var form = $("form#passwordChangeForLoggedUser"),
        self = this;
      form.addClass("was-validated");
      event.stopPropagation();
      event.preventDefault();
      this.isTokenUsed = false;

      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.password) ||
        String.isNullOrWhiteSpace(this.passwordConfirmation) ||
        this.passwordDontMatch ||
        this.formPosting
      ) {
        if (this.passwordDontMatch) {
          this.validatePassword();
        }
        return;
      }

      var model = { ...this.userData };
      model.password = this.$root.encrypt(model.password, true);

      this.formPosting = true;
      this.$appAxios.post("/PasswordChange", model).then((result) => {
        this.formPosting = false;
        var response = result.data,
          message = response.message;

        if (response.isOk) {
          message = this.$t(
            "PasswordChange.SuccessMessage",
            {},
            { locale: this.$store.state.activeLang }
          );
        } else {
          message = this.$root.getMessageByKey(message);
        }

        Swal.fire({
          html: `<span class='fw-bold fs-5'>${message}</span>`,
          icon: response.isOk ? "success" : "error",
          buttonsStyling: false,
          showDenyButton: false,
          confirmButtonText:
            "<i class='fa fa-times'></i>" +
            this.$t(
              "BaseModelFields.Close",
              {},
              { locale: this.activeLanguage }
            ),
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        }).then((result) => {
          if (result.isConfirmed && response.isOk) {
            self.parentWindowModalClose();
          }
        });
      });
    },
  },
  setup() {
    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });
  },
};
</script>
